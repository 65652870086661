export const SHARED_PROFILE_CLICKED = "share profile clicked web";
export const ADD_EMIAL_ID_CLICKED = "add email id clicked web";
export const ADD_HOMETOWN_CLICKED = "add hometown clicked web ";

export const RESUME_CLICKED = "resume clicked web ";
export const UPLOAD_RESUME_SCREEN_SHOWN = "upload resume screen shown web";
export const UPLOADED_RESUME_SCREEN_SHOWN = "uploaded resume screen shown web";
export const RESUME_SAVE_SUCCESSFULLY = "resume saved dsuccessfully web";

export const EDIT_LANGUAGE_KNOW_CLICKED = "edit languages known clicked web ";
export const EDIT_LANGUAGE_KNOW_SCREEN_SHOWN = "edit languages known screen shown web";
export const LANGUAGES_SAVED_SUCCESSFULLY = "languages saved successfully web";

export const EDIT_SKILLS_CLICKED = "edit skills clicked web";
export const EDIT_SKILLS_SCREEN_SHOWN = "edit skills screen shown web";
export const SKILLS_ADDED = "skills added web";
export const SKILL_SEARCHED = "skill searched web";
export const SKILLS_SAVED_SUCCESSFULLY = "skills saved successfully web";

export const PROFILE_PHOTO_CLICKED = "profile photo clicked web";
export const UPDATED_PHOTO_SCREEN_SHOWN = "uploaded photo screen shown web";
export const PROFILE_PHOTO_EXIT_SCREEN_SHOWN = "Profile Photo Exit Screen Shown Web";
export const PROFILE_PHOTO_UPDATED_SUCCESSFULLY = "Profile Photo updated successfully";

export const ADD_JOB_PREFERENCE_CLICKED_WEB = "Add job preference clicked web";
export const EDIT_JOB_PREFERENCE_SCREEN_SHOWN = "edit job preferences screen shown web";
export const JOB_PREFERENCE_UPDATED_SUCCESSFULLY =
	"job preference updated successfully web";

export const EDIT_LOCATION_CLICKED = "Edit Location clicked web ";
export const ADD_DOCUMENT_AND_ASSET_CLICKED = "Add documents and assets clicked web";
export const EDIT_DOCUMENT_AND_ASSET_CLICKED = "Edit documents and assets clicked web";
export const SPOKEN_ENGLISH_CLICKED = "spoken english clicked web";
export const DEPARTMENTS_CLICKED = " Deprtments clicked web";

// Education edit events:

export const ADD_EDUCATION_CLICKED_WEB = "add_education_clicked_web";
export const EDIT_EDUCATION_CLICKED_WEB = "edit_education_clicked_web";
export const EDUCATION_SCREEN_SHOWN_WEB = "education_screen_shown_web ";
export const EDUCATION_LEVEL_ENTERED_WEB = "education_level_entered_web";
export const COLLEGE_NAME_QUERY_SEARCHED_WEB = "college_name_query_searched_web ";
export const COLLEGE_NAME_ENTERED_WEB = "college_name_entered_web";
export const DEGREE_NAME_QUERY_SEARCHED_WEB = "degree_name_query_searched_web ";
export const DEGREE_NAME_ENTERED_WEB = "degree_name_entered_web";
export const SPECIALIZATION_QUERY_SEARCHED_WEB = "specialization_query_searched_web ";
export const SPECIALIZATION_ENTERED_WEB = "specialization_entered_web ";
export const EDUCATION_TYPE_SELECTED_WEB = "education_type_selected_web ";
export const EDUCATION_START_DATE_ENTERED_WEB = "education_start_date_entered_web";
export const EDUCATION_END_DATE_ENTERED_WEB = "education_end_date_entered_web";
export const EDUCATION_DETAILS_SAVED_WEB = "education_details_saved_web";

export const EDUCATION_DETAILS_EDITED_WEB = "education_details_edited_web ";
export const DELETE_EDUCATION_CLICKED_WEB = "delete_education_clicked_web";
export const EDUCATION_DELETE_CONFIRMATION_SCREEN_SHOWN_WEB =
	"education_delete_confirmation_screen_shown_web ";
export const EDUCATION_DELETED_SUCCESSFULLY_WEB = "education_deleted_successfully_web ";
export const EDIT_HIGHEST_EDUCATION_CLICKED_WEB = "Edit Highest Education Clicked Web ";
export const EDIT_HIGHEST_EDUCATION_SCREEN_SHOWN = "Edit Highest Education Screen Shown ";

// Experience edit events:

export const ADD_EXPERIENCE_CLICKED_WEB = "add_experience_clicked_web";
export const EDIT_EXPERIENCE_CLICKED_WEB = "edit_experience_clicked_web";
export const EXPERIENCE_SCREEN_SHOWN_WEB = "experience_screen_shown_web  ";
export const JOB_TITLE_QUERY_SEARCHED_WEB = "job_title_query_searched_web";
export const JOB_TITLE_ENTERED_WEB = "job_title_entered_web ";
export const DEPARTMENT_QUERY_SEARCHED_WEB = "department_query_searched_web";
export const DEPARTMENT_ENTERED_WEB = "department_entered_web ";
export const CATEGORY_QUERY_SEARCHED_WEB = "category_query_searched_web";
export const CATEGORY_ENTERED_WEB = "category_entered_web ";
export const COMPANY_NAME_QUERY_SEARCHED_WEB = "company_name_query_searched_web ";
export const COMPANY_NAME_ENTERED_WEB = "company_name_entered_web ";
export const INDUSTRY_QUERY_SEARCHED_WEB = "industry_query_searched_web";
export const INDUSTRY_ENTERED_WEB = "industry_entered_web";
export const EXPERIENCE_CURRENTLY_WORKING_SELECTED_WEB =
	"experience_currently_working_selected_web ";

export const EXPERIENCE_TYPE_SELECTED_WEB = "experience_type_selected_web  ";
export const EXPERIENCE_START_DATE_ENTERED_WEB = "experience_start_date_entered_web";
export const EXPERIENCE_END_DATE_ENTERED_WEB = "experience_end_date_entered_web ";
export const EXPERIENCE_DETAILS_SAVED_WEB = "experience_details_saved_web ";

export const EXPERIENCE_DETAILS_EDITED_WEB = "experience_details_edited_web  ";
export const DELETE_EXPERIENCE_CLICKED_WEB = "delete_experience_clicked_web";
export const EXPERIENCE_DELETE_CONFIRMATION_SCREEN_SHOWN_WEB =
	"experience_delete_confirmation_screen_shown_web  ";
export const EXPERIENCE_DELETED_SUCCESSFULLY_WEB =
	"experience_deleted_successfully_web  ";

export const EDIT_TOTAL_EXPERIENCE_CLICKED_WEB = "Edit total experience clicked web";
export const TOTAL_EXPERIENCE_CHANGED_WEB = "Total experience changed web";

export const EDIT_SALARY_CLICKED_WEB = "Edit salary clicked web";
export const SALARY_CHANGED_WEB = "Salary changed web ";
export const EDIT_NOTICE_PERIOD_CLICKED_WEB = "Edit Notice Period Clicked web";
export const NOTICE_PERIOD_CHANGED_WEB = "Notice Period changed web";
export const NOTICE_PERIOD_SELECTED_WEB = "Notice Period selected web";
export const EXIT_WITHOUT_SAVING_SHOWN = "exit_without_saving_shown";
export const EDIT_EXPERIENCE_LEVEL_CLICKED_WEB = "Edit Experience Level Clicked Web";
export const EDIT_EXPERIENCE_LEVEL_SCREEN_SHOWN = "Edit Experience Level Screen Shown";

// Preferred Title/Role
export const EDIT_PREFERRED_ROLE_CLICKED_WEB = "Edit Preferred role clicked web";
export const EDIT_PREFERRED_ROLE_SCREEN_SHOWN_WEB =
	"Edit preferred role screen shown web";
export const PREFERRED_ROLE_REMOVED_WEB = "Preferred Role removed web";
export const PREFERRED_ROLE_ADDED_WEB = "Preferred Role added web";
export const PREFERRED_ROLE_SEARCHED_WEB = "Preferred Role searched web";
export const PREFERRED_ROLE_SAVED_SUCCESSFULLY_WEB =
	"Preferred Role saved successfully web";
export const PREFERRED_ROLE_LIMIT_SHOWN_WEB = "Preferred Role Limit shown web";
export const ADD_ATLEAST_ONE_PREFERRED_ROLE_SHOWN_WEB =
	"Add atleast one preferred role shown web";
export const EDIT_PREFERRED_ROLE_SCREEN_CLOSED_WEB =
	"Edit Preferred role screen closed web";

// Resume
export const EDIT_RESUME_SCREEN_SHOWN_WEB = "edit resume screen shown web ";
export const CHANGE_RESUME_ICON_CLICK_WEB = "change resume icon click web ";
export const UPLOAD_RESUME_CTA_CLICKED = "upload resume cta clicked";
export const UPLOADING_RESUME_SCREEN_SHOWN_WEB = "uploading resume screen shown web ";
export const DELETE_RESUME_ICON_CLICK_WEB = "delete resume icon click web";
export const DELETE_RESUME_CTA_CLICKED_WEB = "delete resume cta clicked web";
export const RESUME_DELETED_SUCCESSFULLY_WEB = "resume deleted successfully web";
export const PREVIEW_RESUME_CLICKED_WEB = "preview resume clicked web";
export const RESUME_UPLOADED_WEB = "resume uploaded web ";
export const RESUME_SIZE_TOO_BIG_ERROR_SHOWN = "resume size too big error shown";
export const EDIT_RESUME_SCREEN_CLOSED_WEB = "Edit resume Screen Closed web ";

// Job preference:
export const EDIT_JOB_PREFERENCE_CLICKED_WEB = "Edit job preference clicked web";
export const EDIT_JOB_PREFERENCES_SCREEN_SHOWN_WEB =
	"Edit job preferences screen shown web";
export const JOB_PREFERENCE_SAVED_SUCCESSFULLY_WEB =
	"Job preference saved successfully web";
export const EDIT_JOB_PREFERENCES_SCREEN_CLOSED_WEB =
	"Edit Job Preferences Screen Closed web ";

// Preferred location:
export const EDIT_LOCATION_SCREEN_SHOWN_WEB = "Edit Location screen shown web";
export const PREFERRED_JOB_CITY_SEARCHED_WEB = "Preferred Job City Searched web";
export const PREFERRED_JOB_CITY_ADDED_WEB = "Preferred Job City added web";
export const CURRENT_CITY_LOCATION_ACCESS_CLICKED_WEB =
	"Current city location access clicked web";
export const CURRENT_CITY_FETCHED_WEB = "Current city fetched web";
export const CURRENT_CITY_SEARCHED_WEB = "Current city searched web";
export const CURRENT_CITY_ADDED_WEB = "Current city added web";
export const CURRENT_AREA_SEARCHED_WEB = "Current area searched web";
export const CURRENT_AREA_ADDED_WEB = "Current area added web";
export const HOMETOWN_SEARCHED_WEB = "Hometown searched web";
export const HOME_TOWN_SELECTED_WEB = "Home town selected web";
export const LOCATION_DETAILS_SAVED_WEB = "Location Details Saved Web";
export const EDIT_LOCATION_SCREEN_CLOSED_WEB = "Edit Location Screen Closed web";

// Edit doc & Assets:
export const EDIT_DOCUMENTS_AND_ASSETS_SCREEN_SHOWN_WEB =
	"Edit Documents and assets screen shown web";
export const TIPS_FOR_DUCMENTS_EXPANDED_WEB = "Tips for ducments expanded web";
export const ASSETS_ADDED_WEB = "assets clicked web";
export const DOCUMENT_POSSESSED_ADDED_WEB = "document possessed clicked web";
export const ADD_DOCUMENT_CLICKED_WEB = "Add document clicked web";
export const DOCUMENT_ADDITION_OPTION_CLICKED_WEB =
	"Document addition option clicked web";
export const DOCUMENT_ADDED_WEB = "Document Added Web";
export const CHANGE_DOCUMENT_CLICKED_WEB = "Change Document Clicked web";
export const DOCUMENT_CHANGED_WEB = "Document Changed Web";
export const DOCUMENT_DELETE_CLICKED_WEB = "Document delete clicked web";
export const DOCUMENT_DELETE_CONFIRM_SCREEN_SHOWN_WEB =
	"Document delete confirm screen shown web";
export const DOCUMENT_DELETE_CONFIRM_SCREEN_CTA_CLICKED_WEB =
	"Document delete confirm screen CTA Clicked web";
export const DOCUMENT_DELETED_WEB = "Document deleted web";
export const DOCUMENT_PREVIEW_CLICKED_WEB = "Document preview clicked web";
export const DOCUMENTS_AND_ASSETS_SAVED_WEB = "Documents and assets saved web";
export const EDIT_DOCUMENTS_AND_ASSETS_CLOSED_WEB =
	"Edit Documents and assets closed web";

// Edit basic details:
export const EDIT_BASIC_DETAILS_CLICKED_WEB = "Edit Basic Details Clicked Web";
export const EDIT_BASIC_DETAILS_SCREEN_SHOWN_WEB = "Edit Basic Details Screen Shown  Web";
export const NAME_EDITED_WEB = "Name Edited Web";
export const GENDER_EDITED_WEB = "Gender Edited Web";
export const DOB_EDITED_WEB = "DOB Edited Web";
export const DOB_AGE_LIMIT_ERROR_SHOWN_WEB = "DOB Age limit Error Shown Web";
export const EMAIL_ADDED_WEB = "Email Added Web";
export const VERIFY_EMAIL_CLICKED_WEB = "Verify Email Clicked Web";
export const EMAIL_VERIFICATION_SCREEN_SHOWN_WEB = "Email Verification Screen Shown Web";
export const EDIT_VERIFICATION_EMAIL_CLICKED_WEB = "Edit verification email clicked web";
export const RESEND_OTP_FOR_EMAIL_VERIFICATION_CLICKED_WEB =
	"Resend OTP for email verification clicked web";
export const OTP_ENTERED_FOR_EMAIL_VERIFICATION_WEB =
	"OTP entered for email verification web";
export const INCORRECT_OTP_MESSAGE_SHOWN = "Incorrect OTP message shown";
export const EMAIL_VERIFIED_SUCCESSFULLY_WEB = "Email verified successfully web";
export const BASIC_DETAILS_SAVED_SUCCESSFULLY_WEB =
	"Basic Details Saved Successfully Web";
export const EDIT_BASIC_DETAIL_SCREEN_CLOSED_WEB = "Edit Basic Detail Screen Closed web";

// Properties:
export const SELF_ADD = "self add";
export const SELF_EDIT = "self edit";

// Skills edit Events
export const EDIT_SKILLS_CLICKED_WEB = "edit skills clicked web";
export const EDIT_SKILLS_SCREEN_SHOWN_WEB = "edit skills screen shown web";
export const SKILLS_ADDED_WEB = "skills added web";
export const SKILLS_NAME_NOT_FOUND_WEB = "skill name not found web";
export const SKILLS_SAVED_SUCCESSFULLY_WEB = "skills saved successfully web";
export const ADD_ATLEAST_ONE_SKILL_ERROR_SHOWN = "add atleast one skill error shown";

// Languages Known edit Events
export const EDIT_LANGUAGES_CLICKED_WEB = "edit languages clicked web";
export const EDIT_LANGUAGES_SCREEN_SHOWN_WEB = "edit languages screen shown web";
export const LANGUAGES_SAVED_SUCCESSFULLY_WEB = "languages saved successfully web";

// English Audio Intro in Web:
export const VERIFY_ENGLISH_SHOWN_WEB = "verify_english_shown_web";
export const VERIFY_ENGLISH_CLICKED_WEB = "verify_english_clicked_web";
export const ENGLISH_AUDIO_START_RECORDING_CLICKED_WEB = "english_audio_start_recording_clicked_web";
export const ENGLISH_AUDIO_UPLOADED_WEB = "english_audio_uploaded_web";
export const ENGLISH_AUDIO_UPLOADED_FINAL_WEB = "english_audio_uploaded_final_web";
export const EDIT_AUDIO_CLICKED_WEB = "edit_audio_clicked_web";
export const ENGLISH_AUDIO_START_RECORD_AGAIN_CLICKED = "english_audio_start_record_again_clicked";
export const ENGLISH_AUDIO_ERROR_SHOWN = "english_audio_error_shown";
export const ENGLISH_AUDIO_STATUS_SHOWN_WEB = "English_Audio_status_shown_web";
export const AUDIO_RECORDING_PLAY_CLICKED_WEB = "Audio_recording_play_clicked_web";
export const SPOKEN_ENGLISH_SCREEN_SHOWN_WEB = "spoken_english_screen_shown_web";

// Search MP events properties
export const SEARCH_MP_EVENTS_PROPS_NAME = {
	USER_ENTERED_QUERY: "User Entered Query",
	ENTITY_TYPE: "Entity Type",
	SEARCH_FEATURE_USED: "Search Feature Used",
	USER_ENTERED_LOCATION_QUERY: "User Entered Location Query",
	LOCATION_TYPE: "Location Type",
	LOCATION_SEARCH_FEATURE_USED: "Location Search Feature Used",
	SEARCH_SOURCE: "Search_Source",
	SEARCH_METHOD: "Search Method",
};
