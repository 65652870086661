/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import { useRouter } from "next/router";
import Link from "next/link";
import ApnaLogo from "assets/svgs/ApnaLogoV2";
import DownArrow from "assets/svgs/DownArrow";
import source from "utils/source";
import { getUrlPath } from "utils/helpers";
import AlertBellIcon from "assets/svgs/AlertBellIcon";
import ContactIcon from "assets/svgs/ContactIcon";
import DownloadGrayIcon from "assets/svgs/DownloadGrayIcon";
import StarGrayIcon from "assets/svgs/StarGrayIcons";
import JobIcon from "assets/svgs/jobIcon";
import LoggedinGrayUserIcon from "assets/svgs/LoginGrayUserIcon";
import LogoutRedIcon from "assets/svgs/LogoutRedIcon";
import { isUserLoggedIn, logoutUser } from "utils";
import { SESSION_STORAGE_KEYS } from "utils/constants";
import useJobFeedStore from "components/RevampFeedDetails/JobFeed/store";
import { useCandidateProgress } from "modules/candidate/profile/hooks/useCandidateProgress";
import HomeIcon from "assets/svgs/HomeIcon";
import { useCandidateProfile } from "modules/candidate/profile/hooks/useCandidateProfile";
import { useCandidateDetails } from "modules/candidate/profile/hooks/useCandidateDetails";
import { SECTION_MAP } from "modules/candidate/profile/constants/sectionConstants";
import CommunityIcon from "assets/svgs/CommunityIcon";
import {
	CarrerCompassLabelType,
	JobCategoryLabelType,
	JobCategoryOptionsType,
	JobsLabelType,
	SidebarContainerType,
	SidebarPropTypes,
} from "../types";
import config from "../../../config";
import SIDEBAR_DATA, { LOGIN_DATA, SIDEBAR_CAREER_COMPASS_DATA } from "../SIDEBAR_DATA";

const Sidebar: React.FC<SidebarPropTypes> = ({ isSidebarOpen, closeSidebar }) => {
	const [activeDropDown, setActiveDropDown] = useState(false);
	const [isCareerCompassActive, setIsCareerCompassActive] = useState(false);
	const [isLoginAactive, setIsLoginAactive] = useState(false);
	const [isJobActive, setIsJobActive] = useState(false);
	const [isCommunityDropdownActive, setIsCommunityDropdownActive] = useState(false);
	const [jobContainerHeight, setjobContainerHeight] = useState(0);
	const [activeCategory, setActiveCategory] = useState<JobCategoryOptionsType>("");
	const [sidebarSelectionForJobSearch, setSidebarSelectionForJobSearch] =
		useState<JobCategoryOptionsType>("");
	const router = useRouter();
	const { resetSearch } = useJobFeedStore();
	const { data: candidateOnboardingDetails } = useCandidateProgress();
	const { data: candidateDetails } = useCandidateProfile();
	const { data: profileDetails } = useCandidateDetails(candidateDetails?.phoneNumber);
	const isFresher = !profileDetails?.[SECTION_MAP.PROFILE_EDITOR]?.isExperienced;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { utm_source, utm_medium, utm_campaign } = router.query;

	const logoURl = `/${utm_source ? `?utm_source=${utm_source}&` : ""}${
		utm_medium ? `utm_medium=${utm_medium}&` : ""
	}${utm_campaign ? `utm_campaign=${utm_campaign}` : ""}`;

	const employerLoginUrl = `${config.dashboardUrl}/login?utm_source=${
		router?.query?.utm_source || "Header Menu"
	}&utm_medium=${
		router?.query?.utm_medium || "Candidate%20Hire%20Now%20Button"
	}&utm_campaign=${router?.query?.utm_campaign || "Header Menu"}&employer=true`;

	const handleHireCandidatesClick = () => {
		trackHireCandidatesClick();
		window.open(employerLoginUrl, "_blank");
	};

	const redirectUrlHandle = (url: string) => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track(`Sidebar ${url} Click`, {
				Source: source,
				page: getUrlPath(),
				Section: "Sidebar Menu",
			});
		});
		if (url === "community" || url === "xclusives") {
			window.open(`${config.websiteUrl}${url}`, "_self");
		} else {
			window.open(`${config.websiteUrl}${url}`, "_blank");
		}
	};

	// const handleCategoryClick = (linkPage) => {
	// 	setSidebarSelectionForJobSearch(linkPage);
	// 	// Tracking call for high level headings
	// 	trackSidebarCategorySelection(linkPage);
	// 	setIsCareerCompassActive(false);
	// 	if (!activeDropDown) {
	// 		setActiveDropDown(!activeDropDown);
	// 		setActiveCategory(linkPage);
	// 	}
	// 	if (activeDropDown && activeCategory === linkPage) {
	// 		setActiveDropDown(!activeDropDown);
	// 	} else if (activeDropDown && activeCategory !== linkPage) {
	// 		setActiveCategory(linkPage);
	// 	}
	// };
	const handleCategoryClick = (linkPage, height) => {
		const isActive = activeCategory === linkPage;
		setActiveCategory(isActive ? "" : linkPage); // Toggle category or set new
		setActiveDropDown(!isActive); // Directly toggle dropdown state based on category check
		if (isActive) {
			setjobContainerHeight(0);
		} else {
			setjobContainerHeight(height);
		}
	};
	// For the 3 featured job types in the sidebar top section
	const trackJobTypeClick = (topRowJobType) => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track(`Sidebar ${topRowJobType} Click`, {
				Source: source,
				page: getUrlPath(),
				Section: "Sidebar Menu",
			});
		});
	};
	// For high level job headings listed in the sidebar
	const trackSidebarCategorySelection = (categorySelection) => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track(`Sidebar ${categorySelection} Click`, {
				Source: source,
				page: getUrlPath(),
				Section: "Sidebar Menu",
			});
		});
	};
	// Tracking and initial behavior for top row items
	const handleTopRowJobTypeClick = (e, topRowJobType) => {
		trackJobTypeClick(topRowJobType);
		closeSidebar(e);
	};

	const trackHireCandidatesClick = () => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track(`Sidebar Hire Candidates Click`, {
				Source: source,
				page: getUrlPath(),
				Section: "Sidebar Menu",
			});
		});
	};
	const onLogoutClicked = () => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track(`Sidebar Logout Click`, {
				Source: source,
				page: getUrlPath(),
				Section: "Sidebar Menu",
			});
			Mixpanel.default.reset();
		});

		// Clear values in job feed store
		resetSearch();
		/**
		 * Once the user is logged out, we are redirecting them back to
		 * job listing page, in case he is in any candidate specific route.
		 */
		if (typeof window !== "undefined") {
			sessionStorage.removeItem(SESSION_STORAGE_KEYS.SEARCH_EVENTS_PROPS);
			sessionStorage.removeItem(SESSION_STORAGE_KEYS.PREFILLED_LOCATION);
		}
		if (router.pathname?.includes("/candidate/")) {
			logoutUser({ reload: false, isCandidateRoute: true });
		} else logoutUser();
	};
	return (
		<Container isSidebarOpen={isSidebarOpen}>
			<TopRow>
				<Link href={logoURl} passHref>
					<a>
						<ApnaLogo onClick={closeSidebar} />
					</a>
				</Link>
			</TopRow>
			<div style={{ marginBottom: "12px" }}>
				{isUserLoggedIn() &&
					candidateOnboardingDetails?.isCandidateOnboardingCompleted &&
					isFresher && (
						<Label
							onClick={() => {
								window.open("/home", "_self");
							}}
						>
							<HomeIcon />
							<p>Home</p>
						</Label>
					)}
				<JobsLabel
					activeDropDown={isJobActive}
					page="jobs"
					onClick={() => {
						setIsJobActive(!isJobActive);
						setActiveDropDown(!activeDropDown);
					}}
					aria-hidden
				>
					{" "}
					<div
						className="flex items-center"
						onClick={() => {
							if (
								isUserLoggedIn() &&
								candidateOnboardingDetails?.isCandidateOnboardingCompleted
							) {
								window.open("/jobs", "_self");
							} else {
								window.open("/", "_self");
							}
						}}
						aria-hidden
					>
						<JobIcon />
						<p style={{ margin: 0 }}>Jobs</p>
					</div>
					<DownArrow fill="#190A28" className="down-arrow" />
				</JobsLabel>
				<JobCategorySection
					activeDropDown={isJobActive}
					page="jobs"
					height={
						candidateOnboardingDetails?.isCandidateOnboardingCompleted
							? jobContainerHeight + 210
							: jobContainerHeight + 175
					}
					style={{ color: "#fff" }}
				>
					<GlobalStyle />
					{isUserLoggedIn() &&
						candidateOnboardingDetails?.isCandidateOnboardingCompleted && (
							<a title="Jobs For You">
								<p
									onClick={(e) => {
										resetSearch();

										closeSidebar(e);
										window.open(`/jobs`, "_self");
									}}
									className="mb-[-5px] pl-[15px] !text-[#8c8594]"
								>
									Jobs For You
								</p>
							</a>
						)}
					{SIDEBAR_DATA.map((link) => (
						<JobCategory key={link.page}>
							<JobCategoryLabel
								activeDropDown={activeDropDown}
								onClick={() => {
									resetSearch();
									handleCategoryClick(link.page, link.height);
								}}
								activeCategory={activeCategory}
								page={link.page}
							>
								<p>{link.page}</p>
								<DownArrow fill="#190A28" className="down-arrow" />
							</JobCategoryLabel>

							<JobCategoryOptions
								activeDropDown={activeDropDown}
								activeCategory={activeCategory}
								page={link.page}
							>
								{link.links.map((item) => (
									<Link key={item.label} href={item.url} passHref>
										<a title={item.title}>
											<p
												onClick={(e) => {
													resetSearch();
													closeSidebar(e);
												}}
											>
												{item.label}
											</p>
										</a>
									</Link>
								))}
								{activeCategory !== "Jobs By Type" &&
									activeCategory !== "Jobs By Qualification" &&
									activeCategory !== "Others" && (
										<Link
											href={{
												pathname: "/job-search",
												query: {
													keyword: sidebarSelectionForJobSearch,
												},
											}}
											passHref
										>
											<a>
												<p onClick={closeSidebar}>
													{activeCategory === "Jobs By City" &&
														"All Other Cities"}
													{activeCategory ===
														"Jobs By Department" &&
														"All Other Categories"}
													{activeCategory ===
														"Jobs By Company" &&
														"All Other Companies"}
												</p>
											</a>
										</Link>
									)}
							</JobCategoryOptions>
						</JobCategory>
					))}
					{/* <HireCandidatesLabel onClick={handleHireCandidatesClick}>
					<p>Hire Candidates</p>
					<TopRightArrow />
				</HireCandidatesLabel> */}
				</JobCategorySection>
			</div>

			<div style={{ marginBottom: "12px" }}>
				<DropdownLabel
					activeDropDown={isCareerCompassActive}
					page="carrer-compass"
					onClick={() => {
						setIsCareerCompassActive(!isCareerCompassActive);
						setActiveDropDown(false);
						setjobContainerHeight(0);
					}}
					aria-hidden
				>
					<div
						className="flex items-center"
						// onClick={() => {
						// 	window.open("/resume-builder", "_self");
						// }}
						aria-hidden
					>
						<StarGrayIcon />
						<p style={{ margin: 0 }}>Career Compass</p>
					</div>
					<DownArrow fill="#190A28" className="down-arrow" />
				</DropdownLabel>
				<DropdownOptions
					activeDropDown={isCareerCompassActive}
					page="carrer-compass"
					style={{ color: "#fff" }}
				>
					{SIDEBAR_CAREER_COMPASS_DATA[0].link.map((item) => (
						<div aria-hidden onClick={closeSidebar} className="pl-10">
							<div
								onClick={() => {
									if (item.label === "Blogs") {
										window.open(`${item?.url}`, "_blank");
									} else {
										window.location.href = `${item?.url}`;
									}
									import("../../../utils/Mixpanel").then((Mixpanel) => {
										Mixpanel.default.track(
											`Sidebar ${item.label} Click`,
											{
												Source: source,
												page: getUrlPath(),
												Section: "Sidebar Menu",
											}
										);
									});
								}}
								className="py-[6px] text-[14px] text-[#8c8594]"
								aria-hidden
							>
								{item.label}
							</div>
						</div>
					))}
				</DropdownOptions>
			</div>
			<div style={{ marginBottom: "12px" }}>
				<DropdownLabel
					activeDropDown={isCommunityDropdownActive}
					page="carrer-compass"
					onClick={() => {
						setIsCommunityDropdownActive(!isCommunityDropdownActive);
						setActiveDropDown(false);
						setjobContainerHeight(0);
					}}
					aria-hidden
				>
					<div
						className="flex items-center"
						// onClick={() => {
						// 	redirectUrlHandle("community");
						// }}
						aria-hidden
					>
						<CommunityIcon />
						<p style={{ margin: 0 }}>Community</p>
					</div>
					<DownArrow fill="#190A28" className="down-arrow" />
				</DropdownLabel>
				<DropdownOptions
					activeDropDown={isCommunityDropdownActive}
					page="community"
					style={{ color: "#fff" }}
				>
					<div aria-hidden onClick={closeSidebar} className="pl-10">
						<div
							onClick={() => {
								import("../../../utils/Mixpanel").then((Mixpanel) => {
									Mixpanel.default.track(`Sidebar Community Click`, {
										Source: source,
										page: getUrlPath(),
										Section: "Sidebar Menu",
									});
								});
								redirectUrlHandle("community");
							}}
							className="py-[6px] text-[14px] text-[#8c8594]"
							aria-hidden
						>
							Communities
						</div>
					</div>
					<div aria-hidden onClick={closeSidebar} className="pl-10">
						<div
							onClick={() => {
								import("../../../utils/Mixpanel").then((Mixpanel) => {
									Mixpanel.default.track(`Sidebar Xclusives Click`, {
										Source: source,
										page: getUrlPath(),
										Section: "Sidebar Menu",
									});
								});
								redirectUrlHandle("xclusives");
							}}
							className="py-[6px] text-[14px] text-[#8c8594]"
							aria-hidden
						>
							Xclusives
						</div>
					</div>
				</DropdownOptions>
			</div>
			<div>
				<Label
					onClick={() => {
						redirectUrlHandle("free-job-alerts");
					}}
				>
					<AlertBellIcon />
					<p>Free job alerts on WhatsApp</p>
				</Label>
				<hr className="mt-4" />
				<Label
					onClick={() => {
						redirectUrlHandle("contact-us");
					}}
				>
					<ContactIcon />
					<p>Contact us</p>
				</Label>
				<Label
					onClick={() => {
						redirectUrlHandle("download-apna-app");
					}}
				>
					<DownloadGrayIcon />
					<p>Download Apna app</p>
				</Label>
			</div>

			{isUserLoggedIn() ? (
				<p
					className="!ml-[14px] flex items-center"
					onClick={onLogoutClicked}
					aria-hidden="true"
				>
					<LogoutRedIcon />
					<span className="text-[#fff ml-2.5 text-[14px]">Logout</span>
				</p>
			) : (
				<div>
					<LoginLabel
						activeDropDown={isLoginAactive}
						page="login"
						onClick={() => {
							setIsCareerCompassActive(false);
							setActiveDropDown(false);
							setIsLoginAactive(!isLoginAactive);
							setjobContainerHeight(0);
							import("../../../utils/Mixpanel").then((Mixpanel) => {
								Mixpanel.default.track(`Sidebar Login Click`, {
									Source: source,
									page: getUrlPath(),
									Section: "Sidebar Menu",
								});
							});
						}}
						aria-hidden
					>
						{" "}
						<div className="flex items-center">
							<LoggedinGrayUserIcon />
							<p style={{ margin: 0 }}>Login</p>
						</div>
						<DownArrow fill="#190A28" className="down-arrow" />
					</LoginLabel>
					<LoginOptions
						activeDropDown={isLoginAactive}
						page="login"
						style={{ color: "#fff" }}
					>
						{LOGIN_DATA[0].link.map((item) => (
							<div aria-hidden onClick={closeSidebar} className="pl-10">
								<Link href={`${item.url}`}>{item.label}</Link>
							</div>
						))}
					</LoginOptions>
				</div>
			)}
		</Container>
	);
};

export default Sidebar;

const Container = styled.aside<SidebarContainerType>`
	display: block;
	background: #fff;
	position: fixed;
	overflow: scroll;
	width: 72.2vw;
	top: 0;
	bottom: 0;
	left: 0;
	transition: 0.5s ease-in;
	transform: translate(-100%);
	z-index: 100;
	border-top-right-radius: 24px;
	border-bottom-right-radius: 24px;
	${({ isSidebarOpen }) =>
		isSidebarOpen &&
		css`
			transform: translate(0);
			transition: 0.5s ease;
		`};
	p {
		margin: 0 0 0 0.5rem;
		font-weight: 400;
		color: #190a28;
		cursor: pointer;
		font-size: 14px;
	}
	a {
		text-decoration: none;
		color: #8c8594;
	}

	@media (min-width: 767px) {
		display: none;
	}
	svg {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const TopRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	svg {
		height: 36px;
		width: 36px;
	}
`;

const JobTypeSection = styled.div`
	margin: 0 1rem;
	border-bottom: 1px solid #fff;
`;
const Category = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 1rem 0;
	cursor: pointer;

	svg {
		height: 24px;
		width: 24px;
	}
`;

const JobCategorySection = styled.div<JobsLabelType>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	transition: all 0.4s ease-out;
	overflow: hidden;
	height: 0;
	background-color: ${({ activeDropDown }) => (activeDropDown ? "#fff" : "#fff")};
	${({ activeDropDown, page, height }) =>
		activeDropDown &&
		page === "jobs" &&
		css`
			height: ${height}px;
			margin-top: 8px;
		`};

	a {
		display: block;
		margin: 4px 1rem;
		font-size: 14px;
	}
	p {
		font-size: 14px;
	}
`;

const JobCategory = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	font-weight: 400;
	cursor: pointer;
	p {
		margin: 0px;
	}
`;
const GlobalStyle = createGlobalStyle`
  * {
		-webkit-tap-highlight-color : transparent;
  }
`;
const JobCategoryLabel = styled.div<JobCategoryLabelType>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 6px 1rem;
	padding-left: 40px;
	font-weight: 300;
	transition: all 0.4s ease-in;

	background-color: ${({ activeDropDown, activeCategory, page }) =>
		activeDropDown && activeCategory === page ? "#fff" : ""};

	.down-arrow {
		transition: 0.4s ease-in;
		transform: ${({ activeDropDown, activeCategory, page }) =>
			activeDropDown && activeCategory === page ? "rotate(180deg)" : ""};
	}
	p {
		color: #8c8594;
		font-size: 14px;
	}
`;
const DropdownLabel = styled.div<CarrerCompassLabelType>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 2px 1rem;
	font-weight: 300;
	transition: all 0.4s ease-in;

	background-color: ${({ activeDropDown, page }) =>
		activeDropDown && page === "carrer-compass" ? "#fff" : ""};

	.down-arrow {
		transition: 0.4s ease-in;
		transform: ${({ activeDropDown, page }) =>
			activeDropDown && page === "carrer-compass" ? "rotate(180deg)" : ""};
	}
	p {
		margin-left: 10px !important;
	}
`;

const LoginLabel = styled.div<CarrerCompassLabelType>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 2px 1rem;
	font-weight: 300;
	transition: all 0.4s ease-in;

	background-color: ${({ activeDropDown, page }) =>
		activeDropDown && page === "login" ? "#fff" : ""};

	.down-arrow {
		transition: 0.4s ease-in;
		transform: ${({ activeDropDown, page }) =>
			activeDropDown && page === "login" ? "rotate(180deg)" : ""};
	}
	p {
		margin-left: 10px !important;
	}
`;

const JobsLabel = styled.div<CarrerCompassLabelType>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0rem 1rem;
	font-weight: 300;
	transition: all 0.4s ease-in;
	background-color: ${({ activeDropDown, page }) =>
		activeDropDown && page === "jobs" ? "#fff" : ""};

	.down-arrow {
		transition: 0.4s ease-in;
		transform: ${({ activeDropDown, page }) =>
			activeDropDown && page === "jobs" ? "rotate(180deg)" : ""};
	}
	p {
		margin-left: 10px !important;
	}
`;

const JobCategoryOptions = styled.div<JobCategoryLabelType>`
	width: 100%;
	padding: 0;
	transition: all 0.4s ease-out;
	height: 0;

	${({ activeDropDown, activeCategory, page }) =>
		activeDropDown &&
		activeCategory === page &&
		activeCategory === "Jobs By City" &&
		css`
			height: 130px;
		`};
	${({ activeDropDown, activeCategory, page }) =>
		activeDropDown &&
		activeCategory === page &&
		activeCategory === "Jobs By Department" &&
		css`
			height: 200px;
		`};
	${({ activeDropDown, activeCategory, page }) =>
		activeDropDown &&
		activeCategory === page &&
		activeCategory === "Jobs By Company" &&
		css`
			height: 130px;
		`};
	${({ activeDropDown, activeCategory, page }) =>
		activeDropDown &&
		activeCategory === page &&
		activeCategory === "Jobs By Type" &&
		css`
			height: 220px;
			margin-top: -6px;
		`};
	${({ activeDropDown, activeCategory, page }) =>
		activeDropDown &&
		activeCategory === page &&
		activeCategory === "Jobs By Qualification" &&
		css`
			height: 70px;
		`};
	${({ activeDropDown, activeCategory, page }) =>
		activeDropDown &&
		activeCategory === page &&
		activeCategory === "Others" &&
		css`
			height: 120px;
		`};
	background-color: ${({ activeDropDown, activeCategory, page }) =>
		activeDropDown && activeCategory === page ? "#fff" : "#fff"};
	overflow: hidden;
	p {
		font-size: 14px;
		width: 150px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin: 8px 0;
		font-weight: 400;
		color: #8c8594;
		padding-left: 25px;
	}
`;

const DropdownOptions = styled.div<CarrerCompassLabelType>`
	width: 100%;
	padding: 0;
	transition: all 0.4s ease-out;
	height: 0;
	overflow: hidden;
	background-color: ${({ activeDropDown }) => (activeDropDown ? "#fff" : "#fff")};
	${({ activeDropDown, page }) =>
		activeDropDown &&
		page === "carrer-compass" &&
		css`
			height: 100px;
		`};
	${({ activeDropDown, page }) =>
		activeDropDown &&
		page === "community" &&
		css`
			height: 65px;
		`};

	a {
		display: block;
		margin: 0.25rem 0rem;
		font-size: 14px;
	}
	p {
		font-size: 14px;
	}
`;

const LoginOptions = styled.div<CarrerCompassLabelType>`
	width: 100%;
	padding: 0;
	transition: all 0.4s ease-out;
	height: 0;
	overflow: hidden;
	background-color: ${({ activeDropDown }) => (activeDropDown ? "#fff" : "#fff")};
	${({ activeDropDown, page }) =>
		activeDropDown &&
		page === "login" &&
		css`
			height: 120px;
		`};

	a {
		display: block;
		margin: 0.25rem 0rem;
		font-size: 14px;
	}
	p {
		font-size: 14px;
	}
`;

// const JobCategoryLabel = styled.div<JobCategoryLabelType>`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	width: 100%;
// 	padding: 0.25rem 1rem;
// 	font-weight: 300;
// 	transition: all 0.4s ease-in;
// `;
const HireCandidatesLabel = styled.div`
	display: flex;
	align-items: center;
	color: #fff;
	padding: 0.25rem 0.9rem;
	p {
		margin: 0;
	}
`;
const Label = styled.div`
	display: flex;
	align-items: center;
	color: #fff;
	padding: 0rem 0.9rem;
	margin-bottom: 12px;
	p {
		margin-left: 10px;
	}
`;
