/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
import useSafePush from "hooks/useSafePush";
import React, { useContext, useEffect, useMemo, useState, useLayoutEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import source from "utils/source";
import { getUrlPath } from "utils/helpers";
import ApnaTimeLogo from "assets/svgs/ApnaTimeLogo";
import { isUserLoggedIn } from "utils";
import UserAvatar from "components/UserAvatar";
// import BackArrowIcon from "assets/svgs/BackArrowIcon";
import HamburgerMenu2 from "assets/svgs/HamburgerMenu2";
import { Button as ThemedButton } from "components/ThemedComponent/Common";
import SearchIconOutline from "assets/svgs/SearchIconOutline";
import useJobFeedStore from "components/RevampFeedDetails/JobFeed/store";
import { useCandidateProgress } from "modules/candidate/profile/hooks/useCandidateProgress";
import { useCandidateProfile } from "modules/candidate/profile/hooks/useCandidateProfile";
import { GTAG_EVENTS, GTag } from "utils/Gtag";
import { useWindowResize } from "utils/hooks";
import { useCandidateDetails } from "modules/candidate/profile/hooks/useCandidateDetails";
import NavbarBackIcon from "assets/svgs/NavbarBackIcon";
import { getSearchInputString } from "components/Search/SearchMobile";
import { Container } from "../GlobalComponents";
import ApnaLogo from "../../assets/svgs/ApnaLogoV2";
import MenuItems from "./components/MenuItems";
import Sidebar from "./components/Sidebar";
import Overlay from "./components/Overlay";
import getEmployerLoginURL from "../../utils/getEmployerURL";

import {
	NavContainer,
	StyledNav,
	MenuContainer,
	StyledNavMobile,
	Flex,
	IconsContainer,
	ApnaLogoContainer,
} from "./NavbarStyle";
import { USER_AUTH } from "../LoginRefactored/constants";
import { LoginRefactoredContext } from "../LoginRefactored/LoginContext";

export const NAVBAR_MOBILE = "navbar-mobile";
export const NAVBAR_DESKTOP = "navbar";

const NavbarV2 = ({
	MenuItem,
	containerClassName,
	fromHomepage,
	hideSearchbar = false,
}) => {
	const router = useRouter();
	const { safePush } = useSafePush();
	const startAuth = useContext(LoginRefactoredContext);
	// Check user's onboarding status
	const { data: candidateOnboardingDetails, isLoading: isCandidateOnboardingLoading } =
		useCandidateProgress();
	const { data: candidateDetails } = useCandidateProfile();
	const { data: profileDetails } = useCandidateDetails(candidateDetails?.phoneNumber);
	const {
		isDesktopSearchExpanded,
		setIsDesktopSearchExpanded,
		citySearchObject,
		titleSearchObject,
		experienceSearchObject,
		resetSearch,
		resetFilters,
	} = useJobFeedStore();
	const titleName = titleSearchObject?.display_name
		? titleSearchObject?.display_name
		: typeof titleSearchObject === "string" && titleSearchObject;
	const [isTransparent, setIsTransparent] = useState(fromHomepage);
	const [upperHeight, setUpperHeight] = useState(0);
	const [isMobileView] = useWindowResize();
	// const jobIdQueryParam = useMemo(() => {
	// 	if (router?.pathname === "/job/[...job]") {
	// 		const jobParsed =
	// 			router.query?.job?.[router.query?.job?.length - 1].split("-");
	// 		const jobId = jobParsed[jobParsed?.length - 1];
	// 		return `&&jobId=${jobId}`;
	// 	}
	// 	return null;
	// }, [router.pathname]);

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { utm_source, utm_medium, utm_campaign } = router.query;

	// const logoURl = !isUserLoggedIn()
	// 	? `/${utm_source ? `?utm_source=${utm_source}&` : ""}${
	// 			utm_medium ? `utm_medium=${utm_medium}&` : ""
	// 	  }${utm_campaign ? `utm_campaign=${utm_campaign}` : ""}`
	// 	: `/candidate/jobs/?c_id=${isUserLoggedIn()}`;

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const closeSidebar = (e) => {
		setIsSidebarOpen(false);
		document.body.setAttribute("style", "");
		window.scrollTo(0, e.windowOffset);
	};
	const openSidebar = (e) => {
		trackHamburgerMenuClick();
		setIsSidebarOpen(true);
		e.windowOffset = window.scrollY;
		document.body.setAttribute(
			"style",
			`position: fixed; top: -${e.windowOffset}px; left: 0; right: 0`
		);
	};

	const trackHamburgerMenuClick = () => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Hamburger Menu Click", {
				Source: source,
				page: getUrlPath(),
				Section: "Header Menu",
			});
		});
	};

	useEffect(() => {
		if (isMobileView) {
			setIsDesktopSearchExpanded(false);
		}
		setTimeout(() => {
			router.prefetch("/");
		}, 5000);

		const navbarHeight = document.getElementById("navbar").clientHeight + 48;
		const listener = () => {
			setIsTransparent(fromHomepage && window.scrollY < navbarHeight);
		};

		document.addEventListener("scroll", listener);

		return () => document.removeEventListener("scroll", listener);
	}, []);

	const handleCandidateLoginClick = () => {
		resetFilters();
		// Mixpanel logic
		let screenType = "";
		const urlPath = getUrlPath();
		if (urlPath === "/") screenType = "HomePage";
		if (urlPath.includes("/jobs")) {
			screenType = "Job Listing Page";
		}
		if (urlPath.includes("/job/")) {
			screenType = "Job Detail Page";
		}
		if (urlPath === "/events/immersion-program") {
			screenType = "Immersion Program Page";
		}

		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Candidate login", {
				page: getUrlPath(),
				Section: "Header",
				actionType: "Redirection",
				cta: "Candidate Website Login",
				Screen: screenType,
			});
		});
		GTag(GTAG_EVENTS.CandidateButtonClick);
		if (getUrlPath() === "/events/immersion-program") {
			window.open(
				"?user_auth=phone_number&returnTo=/events/immersion-program",
				"_self"
			);
		} else {
			startAuth();
		}
	};

	const handleLogoClick = () => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Apna logo click", {
				page: getUrlPath(),
				Source: source,
				Section: "Header",
			});
		});
	};

	const handlePostJobClick = () => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Employer login click", {
				page: getUrlPath(),
				Source: source,
				section: "Header",
				actionType: "Redirection",
				cta: "For employers",
			});
		});
		GTag(GTAG_EVENTS.EmployerButtonClick);
		window.open(getEmployerLoginURL(), "_blank");
	};
	const showSearchString = getSearchInputString(
		titleSearchObject,
		experienceSearchObject,
		citySearchObject
	);

	const handleApnaLogoClick = () => {
		resetSearch();
		if (isUserLoggedIn() && !isCandidateOnboardingLoading) {
			// If user onbording is incomplete, return to homepage
			if (!candidateOnboardingDetails?.isCandidateOnboardingCompleted) {
				window.open(
					`/?c_id=${isUserLoggedIn()}${
						utm_source ? `&utm_source=${utm_source}` : ""
					}${utm_medium ? `&utm_medium=${utm_medium}` : ""}${
						utm_campaign ? `&utm_campaign=${utm_campaign}` : ""
					}`,
					"_self"
				);
			} else if (
				isUserLoggedIn() &&
				profileDetails &&
				profileDetails["Professional Details"]?.Experience === undefined
			) {
				window.open(`/home`, "_self");
			} else window.open(`/candidate/jobs/?c_id=${isUserLoggedIn()}`, "_self");
		} else {
			window.open(
				`/${utm_source ? `?utm_source=${utm_source}&` : ""}${
					utm_medium ? `utm_medium=${utm_medium}&` : ""
				}${utm_campaign ? `utm_campaign=${utm_campaign}` : ""}`,
				"_self"
			);
		}
	};
	// const calculateUpperHeight = () => {
	// 	const desktopNav = document.getElementById(NAVBAR_DESKTOP);
	// 	const mobileNav = document.getElementById(NAVBAR_MOBILE);
	// 	const banner = document.getElementById(BANNER_ID);

	// 	const bannerHeight = parseInt(getComputedStyle(banner).height, 10) || 0;
	// 	const headerHeight = Math.max(
	// 		parseInt(getComputedStyle(mobileNav).height, 10) || 0,
	// 		parseInt(getComputedStyle(desktopNav).height, 10) || 0
	// 	);
	// 	setUpperHeight(bannerHeight + headerHeight);
	// };

	// useLayoutEffect(() => {
	// 	calculateUpperHeight();
	// }, []);
	const showUserAvatar = () => {
		const notAlloweddRoutes = [
			"/home",
			"/candidate/jobs/[[...paths]]",
			"/community",
			"/candidate/profile",
		];
		if (isUserLoggedIn() && !notAlloweddRoutes.includes(router.pathname)) return true;
		return false;
	};
	return (
		<>
			{/* NOTE: Do not remove the id, as hero section of homepage depends on it */}
			<StyledNav id={NAVBAR_DESKTOP} isTransparent={isTransparent}>
				<Container className={containerClassName}>
					<div className="flex w-full items-center">
						<div className="flex items-center">
							<div
								onClick={handleLogoClick}
								className="flex w-max cursor-pointer items-center"
							>
								{/* <Link href={logoURl} passHref prefetch={false}> */}
								<a>
									<ApnaTimeLogo
										width={48}
										height={48}
										onClick={handleApnaLogoClick}
									/>
								</a>
								{/* </Link> */}
							</div>

							<MenuItems isDark={false} />
						</div>
						{!fromHomepage && !isDesktopSearchExpanded && !hideSearchbar && (
							<div
								className="flex w-full min-w-[100px] max-w-[484px] cursor-pointer items-center gap-2 rounded-lg border border-solid bg-[#F4F2F6] px-[8px] py-[12px] md:max-w-[400px]"
								onClick={() => {
									setIsDesktopSearchExpanded(!isDesktopSearchExpanded);
								}}
							>
								<SearchIconOutline fill="#8C8594" />
								<p className="m-0 flex-1 truncate whitespace-nowrap text-sm leading-[20px] text-[#8C8594]">
									{showSearchString ? (
										<span className="leading-[20px] text-[#190A28]">
											{showSearchString}
										</span>
									) : (
										"Search jobs by title, company or skill"
									)}
								</p>
							</div>
						)}
						<div
							className={`ml-auto flex ${
								isUserLoggedIn() ? "w-[288px]" : "w-[auto]"
							} justify-end`}
						>
							{isUserLoggedIn() ? (
								<UserAvatar isDark={false} />
							) : (
								<div className="flex items-center gap-[24px]">
									<p
										className="m-0 cursor-pointer text-sm font-semibold text-green-500"
										onClick={handlePostJobClick}
									>
										Employer Login
									</p>

									<ThemedButton
										className="!rounded-[4px] !px-6 !text-sm !font-semibold"
										variant="primary"
										size="medium"
										onClick={handleCandidateLoginClick}
									>
										Candidate Login
									</ThemedButton>
								</div>
							)}
						</div>
					</div>
				</Container>
			</StyledNav>

			{/* NOTE: Do not remove the id, as hero section of homepage depends on it */}
			<StyledNavMobile
				id={NAVBAR_MOBILE}
				isDark={false}
				isTransparent={isTransparent}
			>
				<NavContainer>
					<Flex>
						{/* <BackArrowIcon fill={isDark ? "#fff" : "#333"} /> */}
						<IconsContainer>
							{!isSidebarOpen && (
								<>
									{MenuItem && <MenuItem />}
									{router.pathname === "/job/[...job]" ? (
										<div
											onClick={() => {
												window.open("/jobs", "_self");
											}}
										>
											<NavbarBackIcon />
										</div>
									) : (
										<HamburgerMenu2 onClick={openSidebar} />
									)}
								</>
							)}
						</IconsContainer>
						<div onClick={handleApnaLogoClick}>
							{/* <Link href={logoURl} passHref prefetch={false}> */}
							<ApnaLogoContainer>
								<ApnaLogo />
							</ApnaLogoContainer>
							{/* </Link> */}
						</div>
					</Flex>
					{showUserAvatar() && <UserAvatar isDark={false} />}
					{!isUserLoggedIn() && (
						<div>
							<ThemedButton
								onClick={handlePostJobClick}
								className="mr-2 !rounded-[4px] !border-none !bg-transparent !text-xs !font-semibold !text-green-500"
								size="small"
								variant="primary"
							>
								Employer Login
							</ThemedButton>

							<ThemedButton
								className="!rounded-[4px] !text-xs !font-semibold"
								variant="primary"
								size="small"
								onClick={handleCandidateLoginClick}
							>
								Candidate Login
							</ThemedButton>
						</div>
					)}
				</NavContainer>
			</StyledNavMobile>
			<Sidebar
				isSidebarOpen={isSidebarOpen}
				openSidebar={openSidebar}
				closeSidebar={closeSidebar}
			/>
			<Overlay
				isSidebarOpen={isSidebarOpen}
				openSidebar={openSidebar}
				closeSidebar={closeSidebar}
			/>
		</>
	);
};

export default NavbarV2;
